<!--
 * ======================================
 * 说明： 导入进度查询
 * 作者： Silence
 * 文件： importSchedule.vue
 * 日期： 2023/10/7 22:36
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <div class="my-4">
      <el-form ref="ruleForm" :inline="true" :rules="rules" :model="formSearch" class="web-form-inline"  label-width="80px">
          <el-form-item label="学期" prop="semesterId">
            <el-select class="w-full" v-model="formSearch.semesterId" clearable @change="handleSmester">
              <el-option v-for="item in semesterOptions" :key="item.value" :label="item.semester_name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="科目" prop="subjectId">
            <el-select  class="w-full" v-model="formSearch.lessonId"  @change="handleLesson">
              <el-option v-for="item in lessonList" :key="item.id" :label="item['label']" :value="item['id']"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="指标" prop="studyId">
              <el-cascader
                ref="cascader"
                :props="{value:'key',label:'label'}"
                v-model="formSearch.studyId"
                :options="studyidOptions"
                @change="changeStudyId"
              ></el-cascader>
          </el-form-item>
          <el-form-item label="阶段">
            <el-select v-model="formSearch.stage" placeholder="阶段">
              <el-option :label="`第${item.label}阶段`" :value="item.label" v-for="item in stage" :key="item.label">
                <span style="float: left">{{ `第${item.label}阶段` }}</span>
              </el-option>
            </el-select>
          </el-form-item>

        <el-form-item label="导入状态">
          <el-select v-model="formSearch.status" clearable>
            <el-option label="已完成" value="completed"></el-option>
            <el-option label="未完成" value="Incomplete"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <Scrollbar>
      <Table :showPage="false" :autoLoading="false" :hasAction="false" :options="handleOptions" :loadData="handleLoadData" ref="table">

      </Table>
    </Scrollbar>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {},
    inject: ['WEB'],
    data() {
      return {
        semesterOptions:[], // 学期
        classesTreeOptions: [], // 班级
        subjectOptions: [], // 科目
        blockOptions: [], // 模块
        studyidOptions:[],//指标
        stage:[],//阶段
        classId:[],
        //搜索表单结构
        formSearch: {
          semesterId: "",//学期ID
          blockId: "",//模块ID
          grade: '',//年级ID
          classId: '',//班级ID
          lessonId: '',//备课组长ID
          stage: '',//阶段
          subjectId: "",//科目ID
          studyId: [],//指标
          status: "",//导入状态
        },
        rules: {
          semesterId: [
            { required: true, message: "请选择学期", trigger: "change" }
          ],
          // classId: [
          //   { required: true, message: "请选择班级", trigger: "change" }
          // ],
          grade: [
            { required: true, message: "请选择年级", trigger: "change" }
          ],
          subjectId: [
            { required: true, message: "请选择科目", trigger: "change" }
          ],
          blockId: [
            { required: true, message: "请选择模块", trigger: "change" }
          ],
          studyId: [
            { required: true, message: "请选择指标", trigger: "change" }
          ]
        },
        lessonList:[]
      }
    },
    async mounted() {
      await this.handleLoadSemesterOptions();     // 获取学期选项
      await this.handleLoadSubjectOptions();      // 获取所有科目
      this.getLessonDetail()
    },
    methods: {
      async handleLoadSemesterOptions(){
        await this.$cloud.page("semester/list", {
          size: 99999,
          page: 1,
        }).then(async res => {
          this.semesterOptions = res.children || [];
          this.$set(this.formSearch, 'semesterId', res.children?.[0]?.id);
          // 获取学期后 在获取班级
          // await this.handleLoadClassesTree();
        })
      },

      // 获取班级待选树状数据
      async handleLoadClassesTree() {
        if (this.formSearch.semesterId || this.formSearch.semesterId === 0) {
          this.$nextTick(async ()=>{
            let menuInfo = this.WEB.getMenuInfo();
            let url = '/classes/list/tree';
            await this.$cloud.post(url, {
              semesterId: this.formSearch.semesterId,
              blockId:menuInfo['block_id'],
              menuId:menuInfo.id,
            }).then(res => {
              this.classesTreeOptions = res
              this.$set(this.formSearch, 'classId', res[0]?.children?.[0]?.id);
              this.$set(this.formSearch, 'grade', res[0]?.id)
              this.classId = [res[0]?.id, res[0]?.children?.[0]?.id]
            })
          })
        } else {
          this.classId = []
          this.classesTreeOptions = []
          this.formSearch.classId = ''
          this.formSearch.grade = ''
        }
      },

      handleLoadSubjectOptions(){
        this.$cloud.get('/subject/list',{
          page:1,
          size:99999
        }).then(async res=>{
          // this.subjectOptions = res.children || []
          // this.$set(this.formSearch, 'subjectId', res.children?.[0]?.id);
          // await this.handleLoadBlockOptions(); // 获取所有模块
        })
      },

      // 获取所有模块
      async handleLoadBlockOptions(){
        await this.$cloud.get('/block/list/subject',{
          subjectId: this.formSearch.subjectId
        }).then(res=>{
          this.blockOptions = res || []
          this.$set(this.formSearch, 'blockId', res?.[0]?.id);
          let studyidOptions = res?.[0]?.content || []
          studyidOptions = studyidOptions.filter(v=>{
            let optionSubjectId = v.children[0].children?v.children[0].children[0].subject_id:v.children[0].subject_id
            v['subjectId'] = optionSubjectId
            return optionSubjectId === this.formSearch.subjectId
          })
          console.log('studyidOptions',studyidOptions)
          this.studyidOptions = studyidOptions
          // this.changeStudyId();
        })
      },


      changeStudyId() {
        this.stage = []
        this.formSearch.stage = ''
        // 获取阶段
        let url = 'task/stage'
        this.$cloud.post(url, {
          semesterId: this.formSearch.semesterId,
          grade: this.formSearch.grade,
          blockId: this.formSearch.blockId,
          studyId: this.formSearch.studyId,
          classId: this.formSearch.classId
        }).then(res => {
          this.stage = res
        })
      },


      async handleSmester() {
        this.formSearch['classId'] = ''
        this.formSearch['grade'] = ''
        this.classId = []
        // await this.handleLoadClassesTree()
      },
      handleClasses() {
        this.formSearch['classId'] = ''
        this.formSearch['grade'] = ''
        if (this.classId.length) {
          this.formSearch['grade'] = this.classId[0]
          this.formSearch['classId'] = this.classId[1]
        }
      },

      handleSubject(){
        this.formSearch['studyId'] = []
        this.studyidOptions = []
        this.handleLoadBlockOptions();
      },

      handleSearch(){
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            this.handleRefresh();
          } else {
            return false;
          }
        });
      },

      handleOptions(table) {
        table.setHeader([
          {
            "prop": "gradeName",
            "label": "年级"
          },
          {
            "prop": "class_name",
            "label": "班级"
          },
          {
            "prop": "subject_name",
            "label": "学科"
          },
          {
            "prop": "stageName",
            "label": "阶段"
          },
          {
            "prop": "teacher_name",
            "label": "任课老师"
          },
          {
            "prop": "import_date",
            "label": "末次导入时间"
          },
          {
            "prop": "statusName",
            "label": "导入状态",
          }
        ]);
      },
      handleLoadData(table, {size, page}) {
        this.handleLesson(this.formSearch.lessonId)
        //获取分页数据结构
        console.log('this.formSearch',this.formSearch)
        this.$cloud.get("evaluate/progress", {...this.formSearch}).then(res => {
          let data = {children:res}
          table.setData(data);
        }).catch(err => {
          console.log("报错",err)
        })
      },
      // 刷新
      handleRefresh() {
        this.$nextTick(() => {
          this.$refs['table'].resetPage()
          this.$refs['table'].handleLoadData()
        })
      },
      handleLesson(val){
        console.log('选择备课',val)
        let item = this.lessonList.find(v=> v.id === val)
        console.log('item',item)
        this.formSearch.grade = item.grade
        this.formSearch.subjectId = item.subject.id
        // this.formSearch.blockId = item.blockId
        this.handleLoadBlockOptions();
      },
      // 获取备课组长的年级和学科
      getLessonDetail() {
          this.$cloud.get('lesson/teacher').then(res => {
            console.log("res",res)
            this.lessonList = res.map(v=>{
              v['label'] = v.grade +'年级/' + v.subject['name']
              return v
            })
            this.formSearch.lessonId = this.lessonList[0].id
            this.handleLesson(this.lessonList[0].id)
          })
      }
    },
  }
</script>
<style lang="scss" scoped></style>
